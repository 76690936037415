* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

a {
    color: unset;
    text-decoration: none;
}