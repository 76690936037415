.wedding_header {
    margin: 0 auto;
    max-width: 1920px; /* Set maximum width to 100% of its container */
}

.wedding_cover-image {
    display: flex;
    height: auto; /* Allow the height to adjust automatically to maintain aspect ratio */
    width: 100%; /* Set maximum width to 100% of its container */
}

.wedding_cover-image_portrait {
    display: none;
    height: auto; /* Allow the height to adjust automatically to maintain aspect ratio */
    max-width: 100%; /* Set maximum width to 100% of its container */
}

/* @media screen and (min-width: 551px) {
    .wedding_cover-image_portrait {
        display: none;
    }

    .wedding_cover-image {
        display: flex;
    }
} */

@media screen and (max-width: 550px) {
    .wedding_cover-image_portrait {
        display: none;
    }

    .wedding_cover-image {
        display: flex;
    }
}