.loader_container {
    width: 100%;
    height: 100%;
    background:rgba(255,255,255,0.8); 
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10pt;
}

.loader_container-lottie {
    width: 100%;
    height: 100%;
    background-color: white;
}

.success_card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    margin: auto;
    width: auto;
    max-width: 450px; /* Adjust as needed */
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    gap: 1rem;
}

.success_card-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    margin: 0 1rem;
    text-align: center;
}

.success_card-message {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    margin: 0 1rem;
    text-align: center;
}

.close_button {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 20px;
    right: 20px;
}

.close-button:hover {
    opacity: 0.8; /* Reduce opacity on hover */
}

.close_button img {
    width: 16px;
    height: 16px;
}