@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&display=swap);
.wedding_navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem;
    max-width: 1920px;
    margin: 0 auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
}

.wedding_navbar-links {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    align-items: center;
}

.wedding_navbar-links_logo_full {
    margin-right: 2rem;
}

.wedding_navbar-links_logo_full img {
    width: 159px;
    height: 33px;
}

.wedding_navbar-links_logo {
    margin-right: 2rem;
    display: none;
}

.wedding_navbar-links_logo img {
    width: 55px;
    height: 33px;
}

.wedding_navbar-links_logo a, .wedding_navbar-links_logo_full a {
    cursor: pointer;
}

.wedding_navbar-links_container {
    display: flex;
    flex-direction: row;
}

.wedding_navbar-links-compact_container {
    display: none;
}

.wedding_navbar-links_container p,
.wedding_navbar-menu_container p,
.wedding_navbar-links-compact_container p {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    margin: 0 1rem;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .wedding_navbar-links_container {
        display: flex;
    }

    .wedding_navbar-menu {
        display: none;
    }

    .wedding_navbar-links_logo_full {
        display: none;
    }

    .wedding_navbar-links_logo {
        display: flex;
    }

    .wedding_navbar-links-compact_container {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .wedding_navbar {
        padding: 2rem 2rem
    }

    .wedding_navbar-menu {
        display: flex;
    }

    .wedding_navbar-links_logo_full {
        display: none;
    }

    .wedding_navbar-links_logo {
        display: flex;
    }

    .wedding_navbar-links-compact_container {
        display: flex;
    }
}
.wedding_header {
    margin: 0 auto;
    max-width: 1920px; /* Set maximum width to 100% of its container */
}

.wedding_cover-image {
    display: flex;
    height: auto; /* Allow the height to adjust automatically to maintain aspect ratio */
    width: 100%; /* Set maximum width to 100% of its container */
}

.wedding_cover-image_portrait {
    display: none;
    height: auto; /* Allow the height to adjust automatically to maintain aspect ratio */
    max-width: 100%; /* Set maximum width to 100% of its container */
}

/* @media screen and (min-width: 551px) {
    .wedding_cover-image_portrait {
        display: none;
    }

    .wedding_cover-image {
        display: flex;
    }
} */

@media screen and (max-width: 550px) {
    .wedding_cover-image_portrait {
        display: none;
    }

    .wedding_cover-image {
        display: flex;
    }
}
.rsvp_container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    padding-top: 4rem;
    padding-bottom: 8rem;
}

.rsvp_container-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.rsvp_title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 1rem 2rem;
    width: 100%;
}

.rsvp_title p {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    margin: 0 1rem;
}

.rsvp_question {
    width: 100%;
    padding: 1rem 1rem;
    flex-direction: column;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    /* background-color: #333; */
}

.rsvp_question p {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
}

.rsvp_question input {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    padding: 0.5rem 1rem;
    border: 1px solid rgb(146, 133, 133);
    border-radius: 4px;
}

.rsvp_question-inline {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.rsvp_question-options-button,
.rsvp_question-options-button-selected {
    width: 50%;
    margin: 0 5px;
    padding: 0.5rem 0rem;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-family: var(--font-family);
    font-weight: 500;
}

.rsvp_question-options-button {
    border: 2px solid rgb(210, 210, 210);
    color: rgb(116, 116, 116);

}

.rsvp_question-options-button-selected {
    border: 2px solid #000;
    color: #000;
}

.rsvp_submitButton-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.submitButton {
    margin: 0 5px;
    padding: 0.5rem 5rem;
    text-align: center;
    background-color: rgb(210, 210, 210);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: var(--font-family);
    font-weight: 500;
    color: #000;
}

@media screen and (max-width: 700px) {
    .rsvp_title p {
        font-size: 32px;
    }
    
    .rsvp_title-container {
        padding: 1rem 1rem;
        text-align: center;
    }
}

@media screen and (max-width: 550px) {
    .rsvp_title p {
        font-size: 32px;
    }
    
    .rsvp_title-container {
        padding: 1rem 0rem;
        text-align: center;
    }

    .rsvp_question p,
    .rsvp_question input {
        font-size: 18px;
    }

    .rsvp_container {
        padding: 1rem;
    }
}
.schedule_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    padding: 4rem 0rem;
    grid-gap: 4rem;
    gap: 4rem;
    background-color: white;
    /* background-color: rgba(232, 232, 232, 0.455); */
}

.schedule_container-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    margin: 0 1rem;
}

.full_width_container_container {
    width: 100%;
}

.schedule_item-container {
    width: 100%;
    flex-direction: row;
    display: flex;
    flex: 1 1;
    max-width: 1920px;
}

.schedule_item-container-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.schedule_item-container-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.schedule_item-container-title,
.schedule_item-container-left-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 28px;
    width: 100%;
    flex: 1 1;
}

.schedule_item-container-time,
.schedule_item-container-left-time {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    text-transform: uppercase;
    flex: 1 1;
}

.schedule_item-container-where,
.schedule_item-container-left-where {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 1rem;
    width: 100%;
    text-transform: uppercase;
    flex: 1 1;

}

.schedule_item-container-left-time,
.schedule_item-container-left-title,
.schedule_item-container-left-where {
    text-align: right;
}

.line_container {
    width: 16px;
    display: flex;
    justify-content: center;
    position: relative;
}

.line {
    position: absolute;
    width: 2px;
    background-color: #000;
    top: 0px;
    left: 7px;
    height: 100%;
}

.line_start-outer {
    width: 16px;
    position: absolute;
    background-color: #000;
    height: 16px;
    top: 0px;
    left: 0px;
    border-radius: 8px;
}

.line_start-inner {
    width: 12px;
    position: absolute;
    background-color: #fff;
    height: 12px;
    top: 2px;
    left: 2px;
    border-radius: 6px;
}

@media screen and (max-width: 700px) {
    .schedule_item-container  {
        padding-left: 1rem;
    }

    .schedule_item-container-right {
        width: 100%;
    }
}
.intro_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    padding: 4rem 0rem;
    grid-gap: 2rem;
    gap: 2rem;
}

.intro_message {
    display: flex;
    flex-direction: column;
}

.intro_message p {
    color: #000;
    font-family: var(--font-family); /* TODO: Promijeniti font u pisana slova */
    font-weight: 500;
    font-size: 40px;
    text-align: center;
}

.intro_date {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.intro_date p {
    display: flex;
    color: #000;
    font-family: var(--font-family); /* TODO: Promijeniti font u pisana slova */
    font-weight: 500;
    font-size: 28px;
    text-align: center;
}

.horizontal_line {
    height: 2px;
    max-width: 250px;
    width: 100%;
    background-color: #000;
}


.intro_full-date {
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
}

.intro_full-date p {
    display: flex;
    color: #000;
    font-family: var(--font-family); /* TODO: Promijeniti font u pisana slova */
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
}

.intro_date-container {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.intro_footer-message {
    display: none;
    flex-direction: column;
}

.intro_footer-message p {
    color: #000;
    font-family: var(--font-family); /* TODO: Promijeniti font u pisana slova */
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 550px) {
    .intro_message p {
        font-size: 32px;
    }
    .intro_full-date p {
        font-size: 24px;
    }

    .intro_date p {
        font-size: 20px;
    }

    .intro_footer-message p {
        font-size: 16px;
    }
}
.countdown_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    padding: 10rem 0rem;
    grid-gap: 4rem;
    gap: 4rem;
    background-color: rgba(0, 0, 0, 0.878);
}

.countdown_time {
    display: flex;
    flex-direction: row;
    grid-gap: 5rem;
    gap: 5rem;

}

.countdown_title p {
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    text-align: center;
}

.white_line {
    width: 2px;
    background-color: #FFF;
    height: 100;
}

.container_time_column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container_time-title p {
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
}

.container_time-subtitle p {
    text-transform: uppercase;
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
}

@media screen and (max-width: 900px) {
    .countdown_time  {
        grid-gap: 4rem;
        gap: 4rem;
    }
}

@media screen and (max-width: 700px) {
    .countdown_time  {
        grid-gap: 3rem;
        gap: 3rem;
    }
}

@media screen and (max-width: 600px) {
    .countdown_time  {
        grid-gap: 2rem;
        gap: 2rem;
    }
}

@media screen and (max-width: 430px) {
    .countdown_time  {
        grid-gap: 1rem;
        gap: 1rem;
    }
}
.story_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    padding-top: 4rem;
    grid-gap: 4rem;
    gap: 4rem;
    background-color: rgba(232, 232, 232, 0.455);
}

.story_container-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    margin: 0 1rem;
}

.story_item-container {
    width: 100%;
    flex-direction: row;
    display: flex;
    flex: 1 1;
    max-width: 1920px;
}

.story_item-container-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2rem;
}

.story_item-container-full {
    width: 100%;
    display: none;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
}

.story_item-container-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 4rem;
    justify-content: center;
}

.story_item-container-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    text-transform: uppercase;
}

.story_item-container-subtitle {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
}

.story_cover-image {
    display: flex;
    align-items: center;
    height: 100%; /* Allow the height to adjust automatically to maintain aspect ratio */
    width: auto; /* Set maximum width to 100% of its container */
}
@media screen and (max-width: 900px) {
    .story_item-container-left  {
        display: none;
    }

    .story_item-container-right {
        width: 100%;
    }

    .story_cover-image {
        height: auto;
        width: 100%;
    }

    .story_item-container-full {
        display: flex;
    }

    .story_item-container {
        flex-direction: column;
    }
}
.image-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.image-upload-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 10pt;
    background-color: white;
    border-radius: 48px; /* Adds rounded corners */
    width: 100%;
    height: 100%;
}

.image-upload-card-border {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border-radius: 32px; /* Adds rounded corners */
    border: 1pt dashed #c0c0c0;
    padding: 10pt;
    padding-bottom: 20pt;
}

.image-upload-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.file-upload-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.file-upload-container p {
    text-align: center;
}

.rocket_animation_container-lottie {
    width: 150pt;
    max-height: 150pt;
}

.image-upload-card-icon img {
    width: 50pt;
    height: 50pt;
}

.image-upload-card-title p {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
    text-align: center;
}

.image-upload-card-title {
    padding-bottom: 10pt;
}

.image-upload-card-button:hover {
    background-color: #333;   /* Darker shade on hover */
} 

.image-upload-card-button {
    margin: 0 5px;
    padding: 0.75rem 5rem;
    text-align: center;
    background-color: black;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: var(--font-family);
    font-weight: 500;
    color: white;
}

.hidenn {
    display: none;
}

@media screen and (max-width: 550px) {
    .image-upload-card-button {
        padding: 0.75rem;
        width: 100%;
    }

    .image-upload-card-button-container {
        width: 100%;
    }

    .image-upload-card {
        width: 90%;
    }
}
/* Grid.css */
.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates 3 equal columns */
    grid-gap: 20px;
    gap: 20px; /* Spacing between grid items */
    padding: 6rem;
    max-width: 1920px;
    background-color: rgba(0, 0, 0, 0.878);
}

.grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container without distortion */
}

.grid-item {
    background-color: #ffffff;
    box-shadow: 0 0 25px rgb(255, 255, 255, 0.1);
    overflow: hidden;
    display: flex; /* Ensure content is centered */
    justify-content: center; /* Horizontally center the image */
    align-items: center; /* Vertically center the image */
    width: 100%;
    aspect-ratio: 1.0;
}

@media screen and (max-width: 1200px) {
    .grid-container  {
        grid-template-columns: repeat(3, 1fr); /* Creates 3 equal columns */
    }
}

@media screen and (max-width: 900px) {
    .grid-container  {
        grid-template-columns: repeat(2, 1fr); /* Creates 3 equal columns */
        padding: 2rem;
        grid-gap: 2rem;
        gap: 2rem;
    }
}

@media screen and (max-width: 550px) {
    .grid-container  {
        grid-template-columns: repeat(1, 1fr); /* Creates 3 equal columns */
        padding: 2rem;
        grid-gap: 2rem;
        gap: 2rem;
    }
}
.memories-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    max-width: 1920px;
    margin: 0 auto;
    padding: 4rem 4rem;
    grid-gap: 4rem;
    gap: 4rem;
    background-color: rgba(232, 232, 232, 0.455);
}

.memories-text-container {
    display: flex;
    width: 50%;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.memories-uploadBox-container {
    display: flex;
    width: 50%;
    flex-grow: 1; /* Expands to match the height of the text container */
}

.memories_item-container-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
}

@media screen and (max-width: 900px) {
    .memories-container  {
        flex-direction: column;
    }

    .memories-text-container {
        width: 100%;
    }

    .memories-uploadBox-container {
        width: 100%;
    }
}
.loader_container {
    width: 100%;
    height: 100%;
    background:rgba(255,255,255,0.8); 
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10pt;
}

.loader_container-lottie {
    width: 100%;
    height: 100%;
    background-color: white;
}

.success_card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    margin: auto;
    width: auto;
    max-width: 450px; /* Adjust as needed */
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    grid-gap: 1rem;
    gap: 1rem;
}

.success_card-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    margin: 0 1rem;
    text-align: center;
}

.success_card-message {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    margin: 0 1rem;
    text-align: center;
}

.close_button {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 20px;
    right: 20px;
}

.close-button:hover {
    opacity: 0.8; /* Reduce opacity on hover */
}

.close_button img {
    width: 16px;
    height: 16px;
}
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

a {
    color: unset;
    text-decoration: none;
}
:root {
  --font-family: 'Noto Serif Display', serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #fff;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}
