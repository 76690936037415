.schedule_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    padding: 4rem 0rem;
    gap: 4rem;
    background-color: white;
    /* background-color: rgba(232, 232, 232, 0.455); */
}

.schedule_container-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    margin: 0 1rem;
}

.full_width_container_container {
    width: 100%;
}

.schedule_item-container {
    width: 100%;
    flex-direction: row;
    display: flex;
    flex: 1;
    max-width: 1920px;
}

.schedule_item-container-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.schedule_item-container-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.schedule_item-container-title,
.schedule_item-container-left-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 28px;
    width: 100%;
    flex: 1;
}

.schedule_item-container-time,
.schedule_item-container-left-time {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    text-transform: uppercase;
    flex: 1;
}

.schedule_item-container-where,
.schedule_item-container-left-where {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 1rem;
    width: 100%;
    text-transform: uppercase;
    flex: 1;

}

.schedule_item-container-left-time,
.schedule_item-container-left-title,
.schedule_item-container-left-where {
    text-align: right;
}

.line_container {
    width: 16px;
    display: flex;
    justify-content: center;
    position: relative;
}

.line {
    position: absolute;
    width: 2px;
    background-color: #000;
    top: 0px;
    left: 7px;
    height: 100%;
}

.line_start-outer {
    width: 16px;
    position: absolute;
    background-color: #000;
    height: 16px;
    top: 0px;
    left: 0px;
    border-radius: 8px;
}

.line_start-inner {
    width: 12px;
    position: absolute;
    background-color: #fff;
    height: 12px;
    top: 2px;
    left: 2px;
    border-radius: 6px;
}

@media screen and (max-width: 700px) {
    .schedule_item-container  {
        padding-left: 1rem;
    }

    .schedule_item-container-right {
        width: 100%;
    }
}