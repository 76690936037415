.image-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.image-upload-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 10pt;
    background-color: white;
    border-radius: 48px; /* Adds rounded corners */
    width: 100%;
    height: 100%;
}

.image-upload-card-border {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border-radius: 32px; /* Adds rounded corners */
    border: 1pt dashed #c0c0c0;
    padding: 10pt;
    padding-bottom: 20pt;
}

.image-upload-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.file-upload-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.file-upload-container p {
    text-align: center;
}

.rocket_animation_container-lottie {
    width: 150pt;
    max-height: 150pt;
}

.image-upload-card-icon img {
    width: 50pt;
    height: 50pt;
}

.image-upload-card-title p {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
    text-align: center;
}

.image-upload-card-title {
    padding-bottom: 10pt;
}

.image-upload-card-button:hover {
    background-color: #333;   /* Darker shade on hover */
} 

.image-upload-card-button {
    margin: 0 5px;
    padding: 0.75rem 5rem;
    text-align: center;
    background-color: black;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: var(--font-family);
    font-weight: 500;
    color: white;
}

.hidenn {
    display: none;
}

@media screen and (max-width: 550px) {
    .image-upload-card-button {
        padding: 0.75rem;
        width: 100%;
    }

    .image-upload-card-button-container {
        width: 100%;
    }

    .image-upload-card {
        width: 90%;
    }
}