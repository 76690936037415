.wedding_navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem;
    max-width: 1920px;
    margin: 0 auto;
    position: sticky;
    top: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
}

.wedding_navbar-links {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

.wedding_navbar-links_logo_full {
    margin-right: 2rem;
}

.wedding_navbar-links_logo_full img {
    width: 159px;
    height: 33px;
}

.wedding_navbar-links_logo {
    margin-right: 2rem;
    display: none;
}

.wedding_navbar-links_logo img {
    width: 55px;
    height: 33px;
}

.wedding_navbar-links_logo a, .wedding_navbar-links_logo_full a {
    cursor: pointer;
}

.wedding_navbar-links_container {
    display: flex;
    flex-direction: row;
}

.wedding_navbar-links-compact_container {
    display: none;
}

.wedding_navbar-links_container p,
.wedding_navbar-menu_container p,
.wedding_navbar-links-compact_container p {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    margin: 0 1rem;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .wedding_navbar-links_container {
        display: flex;
    }

    .wedding_navbar-menu {
        display: none;
    }

    .wedding_navbar-links_logo_full {
        display: none;
    }

    .wedding_navbar-links_logo {
        display: flex;
    }

    .wedding_navbar-links-compact_container {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .wedding_navbar {
        padding: 2rem 2rem
    }

    .wedding_navbar-menu {
        display: flex;
    }

    .wedding_navbar-links_logo_full {
        display: none;
    }

    .wedding_navbar-links_logo {
        display: flex;
    }

    .wedding_navbar-links-compact_container {
        display: flex;
    }
}