/* Grid.css */
.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates 3 equal columns */
    gap: 20px; /* Spacing between grid items */
    padding: 6rem;
    max-width: 1920px;
    background-color: rgba(0, 0, 0, 0.878);
}

.grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container without distortion */
}

.grid-item {
    background-color: #ffffff;
    box-shadow: 0 0 25px rgb(255, 255, 255, 0.1);
    overflow: hidden;
    display: flex; /* Ensure content is centered */
    justify-content: center; /* Horizontally center the image */
    align-items: center; /* Vertically center the image */
    width: 100%;
    aspect-ratio: 1.0;
}

@media screen and (max-width: 1200px) {
    .grid-container  {
        grid-template-columns: repeat(3, 1fr); /* Creates 3 equal columns */
    }
}

@media screen and (max-width: 900px) {
    .grid-container  {
        grid-template-columns: repeat(2, 1fr); /* Creates 3 equal columns */
        padding: 2rem;
        gap: 2rem;
    }
}

@media screen and (max-width: 550px) {
    .grid-container  {
        grid-template-columns: repeat(1, 1fr); /* Creates 3 equal columns */
        padding: 2rem;
        gap: 2rem;
    }
}