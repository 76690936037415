.memories-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    max-width: 1920px;
    margin: 0 auto;
    padding: 4rem 4rem;
    gap: 4rem;
    background-color: rgba(232, 232, 232, 0.455);
}

.memories-text-container {
    display: flex;
    width: 50%;
    flex-direction: column;
    gap: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.memories-uploadBox-container {
    display: flex;
    width: 50%;
    flex-grow: 1; /* Expands to match the height of the text container */
}

.memories_item-container-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
}

@media screen and (max-width: 900px) {
    .memories-container  {
        flex-direction: column;
    }

    .memories-text-container {
        width: 100%;
    }

    .memories-uploadBox-container {
        width: 100%;
    }
}