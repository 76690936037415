.rsvp_container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    padding-top: 4rem;
    padding-bottom: 8rem;
}

.rsvp_container-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.rsvp_title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 1rem 2rem;
    width: 100%;
}

.rsvp_title p {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    margin: 0 1rem;
}

.rsvp_question {
    width: 100%;
    padding: 1rem 1rem;
    flex-direction: column;
    display: flex;
    gap: 1rem;
    /* background-color: #333; */
}

.rsvp_question p {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
}

.rsvp_question input {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    padding: 0.5rem 1rem;
    border: 1px solid rgb(146, 133, 133);
    border-radius: 4px;
}

.rsvp_question-inline {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.rsvp_question-options-button,
.rsvp_question-options-button-selected {
    width: 50%;
    margin: 0 5px;
    padding: 0.5rem 0rem;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-family: var(--font-family);
    font-weight: 500;
}

.rsvp_question-options-button {
    border: 2px solid rgb(210, 210, 210);
    color: rgb(116, 116, 116);

}

.rsvp_question-options-button-selected {
    border: 2px solid #000;
    color: #000;
}

.rsvp_submitButton-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.submitButton {
    margin: 0 5px;
    padding: 0.5rem 5rem;
    text-align: center;
    background-color: rgb(210, 210, 210);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: var(--font-family);
    font-weight: 500;
    color: #000;
}

@media screen and (max-width: 700px) {
    .rsvp_title p {
        font-size: 32px;
    }
    
    .rsvp_title-container {
        padding: 1rem 1rem;
        text-align: center;
    }
}

@media screen and (max-width: 550px) {
    .rsvp_title p {
        font-size: 32px;
    }
    
    .rsvp_title-container {
        padding: 1rem 0rem;
        text-align: center;
    }

    .rsvp_question p,
    .rsvp_question input {
        font-size: 18px;
    }

    .rsvp_container {
        padding: 1rem;
    }
}