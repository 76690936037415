.story_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    padding-top: 4rem;
    gap: 4rem;
    background-color: rgba(232, 232, 232, 0.455);
}

.story_container-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    margin: 0 1rem;
}

.story_item-container {
    width: 100%;
    flex-direction: row;
    display: flex;
    flex: 1;
    max-width: 1920px;
}

.story_item-container-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2rem;
}

.story_item-container-full {
    width: 100%;
    display: none;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
}

.story_item-container-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 4rem;
    justify-content: center;
}

.story_item-container-title {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    text-transform: uppercase;
}

.story_item-container-subtitle {
    color: #000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
}

.story_cover-image {
    display: flex;
    align-items: center;
    height: 100%; /* Allow the height to adjust automatically to maintain aspect ratio */
    width: auto; /* Set maximum width to 100% of its container */
}
@media screen and (max-width: 900px) {
    .story_item-container-left  {
        display: none;
    }

    .story_item-container-right {
        width: 100%;
    }

    .story_cover-image {
        height: auto;
        width: 100%;
    }

    .story_item-container-full {
        display: flex;
    }

    .story_item-container {
        flex-direction: column;
    }
}