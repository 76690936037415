.countdown_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    padding: 10rem 0rem;
    gap: 4rem;
    background-color: rgba(0, 0, 0, 0.878);
}

.countdown_time {
    display: flex;
    flex-direction: row;
    gap: 5rem;

}

.countdown_title p {
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
    text-align: center;
}

.white_line {
    width: 2px;
    background-color: #FFF;
    height: 100;
}

.container_time_column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container_time-title p {
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 32px;
}

.container_time-subtitle p {
    text-transform: uppercase;
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
}

@media screen and (max-width: 900px) {
    .countdown_time  {
        gap: 4rem;
    }
}

@media screen and (max-width: 700px) {
    .countdown_time  {
        gap: 3rem;
    }
}

@media screen and (max-width: 600px) {
    .countdown_time  {
        gap: 2rem;
    }
}

@media screen and (max-width: 430px) {
    .countdown_time  {
        gap: 1rem;
    }
}