.intro_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    padding: 4rem 0rem;
    gap: 2rem;
}

.intro_message {
    display: flex;
    flex-direction: column;
}

.intro_message p {
    color: #000;
    font-family: var(--font-family); /* TODO: Promijeniti font u pisana slova */
    font-weight: 500;
    font-size: 40px;
    text-align: center;
}

.intro_date {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.intro_date p {
    display: flex;
    color: #000;
    font-family: var(--font-family); /* TODO: Promijeniti font u pisana slova */
    font-weight: 500;
    font-size: 28px;
    text-align: center;
}

.horizontal_line {
    height: 2px;
    max-width: 250px;
    width: 100%;
    background-color: #000;
}


.intro_full-date {
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
}

.intro_full-date p {
    display: flex;
    color: #000;
    font-family: var(--font-family); /* TODO: Promijeniti font u pisana slova */
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
}

.intro_date-container {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.intro_footer-message {
    display: none;
    flex-direction: column;
}

.intro_footer-message p {
    color: #000;
    font-family: var(--font-family); /* TODO: Promijeniti font u pisana slova */
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 550px) {
    .intro_message p {
        font-size: 32px;
    }
    .intro_full-date p {
        font-size: 24px;
    }

    .intro_date p {
        font-size: 20px;
    }

    .intro_footer-message p {
        font-size: 16px;
    }
}